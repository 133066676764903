.tab-user-card {
  .nav-pills {
    background: #ecf0f5;
    padding: 20px;
    border-radius: 5px;
  }
}

.dataTables_wrapper .dataTables_paginate .paginate_button.active {
  background: $primary-color;
  border-color: $primary-color;
}
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --main-bg: #ffffff;
  --second-bg: #fafafb;
  --txt-color: #455560;
  --txt-white: #fff;
  --main-color: #349eff;
  --second-color: #62b4ff;
  --box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-light: #ffffff;
  --second-bg-light: #fafafb;
  --txt-color-light: #455560;
  --box-shadow-light: rgba(149, 157, 165, 0.2) 0px 8px 24px;

  --main-bg-dark: #2d2d2d;
  --second-bg-dark: #202020;
  --txt-color-dark: #bbbbbb;
  --box-shadow-dark: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-bg-green: honeydew;
  --second-bg-green: honeydew;
  --txt-color-green: darkgreen;
  --box-shadow-green: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-bg-blue: lightblue;
  --second-bg-blue: lightblue;
  --txt-color-blue: darkblue;
  --box-shadow-blue: rgba(0, 0, 0, 0.2) 0px 5px 10px;

  --main-color-blue: #349eff;
  --second-color-blue: deeppink;

  --main-color-red: #fb0b12;
  --second-color-red: darkblue;

  --main-color-black: #000;
  --second-color-black: gray;

  --main-color-cyan: #10d4d2;
  --second-color-cyan: royalblue;

  --main-color-green: #019707;
  --second-color-green: mediumturquoise;

  --main-color-orange: #d68102;
  --second-color-orange: darkblue;

  --sidebar-width: 300px;
  --border-radius: 15px;
  --topnav-height: 110px;
  --transition-cubic: cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  -webkit-tap-highlight-color: transparent;
}

body {
  font-family: "arial", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  letter-spacing: 0.7px;
  // overflow-y: hidden;
}

a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: var(--main-color);
}

button {
  cursor: pointer;
  border: 0;
  outline: 0;
  color: var(--txt-color);
}

ul {
  list-style-type: none;
}

input {
  border: 2px solid transparent;
  outline: 0;
}

input:focus {
  border: 2px solid var(--main-color);
}

.page-header {
  margin-bottom: 40px;
  text-transform: capitalize;
}

.card {
  padding: 30px;
  margin-bottom: 30px;
  background-color: var(--main-bg);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
}

.full-height {
  height: 100%;
}

.card.full-height {
  height: calc(100% - 30px);
}

.card__header {
  text-transform: capitalize;
}

.card > div ~ div {
  margin-top: 30px;
}

.card__footer {
  text-align: center;
  text-transform: capitalize;
}

.light-background {
  background-color: var(--main-bg-light);
  color: #000;
}

.dark-background {
  background-color: var(--main-bg-dark);
  color: #fff;
}
.green-background {
  background-color: green;
  color: #fff;
}
.blue-background {
  background-color: #349eff;
  color: #fff;
}
.blue-color {
  background-color: var(--main-color-blue);
  color: #fff;
}

.red-color {
  background-color: var(--main-color-red);
  color: #fff;
}

.black-color {
  background-color: var(--main-color-black);
  color: #fff;
}
.cyan-color {
  background-color: var(--main-color-cyan);
  color: #fff;
}

.green-color {
  background-color: var(--main-color-green);
  color: #fff;
}

.orange-color {
  background-color: var(--main-color-orange);
  color: #fff;
}

/*
    Note: It is best to use a less version of this file ( see http://css2less.cc
    For the media queries use @screen-sm-min instead of 768px.
    For .omb_spanOr use @body-bg instead of white.
*/

@media (min-width: 768px) {
  .omb_row-sm-offset-3 div:first-child[class*="col-"] {
    margin-left: 25%;
  }
}

.omb_login .omb_authTitle {
  text-align: center;
  line-height: 300%;
}

.omb_login .omb_socialButtons a {
  color: white;
  opacity: 0.9;
}
.omb_login .omb_socialButtons a:hover {
  color: white;
  opacity: 1;
}
.omb_login .omb_socialButtons .omb_btn-facebook {
  background: #3b5998;
}
.omb_login .omb_socialButtons .omb_btn-twitter {
  background: #00aced;
}
.omb_login .omb_socialButtons .omb_btn-google {
  background: #c32f10;
}

.omb_login .omb_loginOr {
  position: relative;
  font-size: 1.5em;
  color: #aaa;
  margin-top: 1em;
  margin-bottom: 1em;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
}
.omb_login .omb_loginOr .omb_hrOr {
  background-color: #cdcdcd;
  height: 1px;
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.omb_login .omb_loginOr .omb_spanOr {
  display: block;
  position: absolute;
  left: 50%;
  top: -0.6em;
  margin-left: -1.5em;
  background-color: white;
  width: 3em;
  text-align: center;
}

.omb_login .omb_loginForm .input-group.i {
  width: 2em;
}
.omb_login .omb_loginForm .help-block {
  color: red;
}

@media (min-width: 768px) {
  .omb_login .omb_forgotPwd {
    text-align: right;
    margin-top: 10px;
  }
}
.dropdown__content.active {
  padding: 5px;
}
/* CSS Sort Icon Table */
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:before {
  right: 5px;
  content: "\f0de";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  font-size: 1rem;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  position: absolute;

  display: block;
  opacity: 0.3;
}
table.dataTable thead .sorting:after,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc_disabled:after {
  content: "\f0dd";
  font-family: "Font Awesome\ 5 Free";
  font-weight: 900;
  right: 5px;
  font-size: 1rem;
}
.customer table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  position: absolute;
  bottom: 0.4em !important;
  display: block;
  opacity: 0.3;
}
/* Table CSS */
.checkTable table.dataTable thead .sorting:after,
table.dataTable thead .sorting:before,
table.dataTable thead .sorting_asc:after,
table.dataTable thead .sorting_asc:before,
table.dataTable thead .sorting_asc_disabled:after,
table.dataTable thead .sorting_asc_disabled:before,
table.dataTable thead .sorting_desc:after,
table.dataTable thead .sorting_desc:before,
table.dataTable thead .sorting_desc_disabled:after,
table.dataTable thead .sorting_desc_disabled:before {
  bottom: 1em !important;
}
table.dataTable thead .sorting,
table.dataTable thead .sorting_asc,
table.dataTable thead .sorting_asc_disabled,
table.dataTable thead .sorting_desc,
table.dataTable thead .sorting_desc_disabled {
  position: relative;
  cursor: pointer;
  vertical-align: middle;
}
/* Check Recruitment */
.checkTable tbody tr:hover {
  color: #fff;
}
.modal-form.page-content {
  max-height: 870px;
}
.page-content {
  width: 100%;
  max-height: 760px;
  margin: 0 auto;
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -o-justify-content: center;
  -ms-justify-content: center;
  -moz-justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  -o-align-items: center;
  -ms-align-items: center;
  -moz-align-items: center;
  -webkit-align-items: center;
  background-image: -moz-linear-gradient(
    136deg,
    rgb(149, 153, 226) 0%,
    rgb(139, 198, 236) 100%
  );
  background-image: -webkit-linear-gradient(
    136deg,
    rgb(149, 153, 226) 0%,
    rgb(139, 198, 236) 100%
  );
  background-image: -ms-linear-gradient(
    136deg,
    rgb(149, 153, 226) 0%,
    rgb(139, 198, 236) 100%
  );
}
.table {
  margin-bottom: 0;
}
.form-v10-content {
  background: #fff;
  width: 1100px;
  border-radius: 10px;
  -o-border-radius: 10px;
  -ms-border-radius: 10px;
  -moz-border-radius: 10px;
  -webkit-border-radius: 10px;
  box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 8px 20px 0px rgba(0, 0, 0, 0.15);
  margin: 35px 0;
  position: relative;
  font-family: "Montserrat", sans-serif;
}
.form-v10-content .form-detail {
  position: relative;
  width: 100%;
  display: flex;
  display: -webkit-flex;
}
.form-v10-content .form-detail h2 {
  font-weight: 500;
  font-size: 25px;
  margin-bottom: 34px;
  padding: 33px 50px 0px 60px;
}
.form-v10-content .form-detail .form-left {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  width: 100%;
}
.form-v10-content .form-detail .form-left h2 {
  color: #2271dd;
}
.form-v10-content .form-detail .form-right {
  width: 100%;
  background: dodgerblue;
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}
.form-v10-content .form-detail .form-right h2 {
  color: #fff;
}
.form-v10-content .form-detail .form-group {
  display: flex;
  display: -webkit-flex;
}
.form-v10-content .form-detail .form-row {
  position: relative;
  margin-bottom: 24px;
  padding-left: 60px;
  padding-right: 50px;
}
.form-v10-content .form-detail .form-left .form-group .form-row.form-row-1 {
  width: 50%;
  padding: 0 12px 0 60px;
}
.form-v10-content .form-detail .form-left .form-group .form-row.form-row-2 {
  width: 50%;
  padding: 0 50px 0 12px;
}
.form-v10-content .form-detail .form-left .form-group .form-row.form-row-3 {
  width: 73%;
  padding: 0 12px 0 60px;
}
.form-v10-content .form-detail .form-left .form-group .form-row.form-row-4 {
  width: 50%;
  padding: 0 50px 0 12px;
}
.form-v10-content .form-detail .form-right .form-group .form-row.form-row-1 {
  width: 50%;
  padding: 0 12px 0 60px;
}
.form-v10-content .form-detail .form-right .form-group .form-row.form-row-2 {
  width: 100%;
  padding: 0 50px 0 12px;
}
.form-v10-content .form-detail select,
.form-v10-content .form-detail input {
  width: 100%;
  padding: 11.5px 15px 15px 15px;
  border: 1px solid transparent;
  background: transparent;
  appearance: unset;
  -moz-appearance: unset;
  -webkit-appearance: unset;
  -o-appearance: unset;
  -ms-appearance: unset;
  outline: none;
  -moz-outline: none;
  -webkit-outline: none;
  -o-outline: none;
  -ms-outline: none;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -o-box-sizing: border-box;
  -ms-box-sizing: border-box;
}
.form-v10-content .form-detail select {
  background: 0 0;
  position: relative;
  z-index: 9;
  cursor: pointer;
}
.form-v10-content .form-detail .form-left select {
  color: #666;
}
.form-v10-content .form-detail .form-right select {
  color: #f2f2f2;
}
.form-v10-content .form-detail .form-right select option {
  color: #000;
}
.form-v10-content .form-detail .select-btn {
  z-index: 0;
  position: absolute;
  top: 20%;
  right: 15.5%;
  font-size: 18px;
}
.modal-form .form-v10-content .form-detail .select-btn {
  z-index: 0;
  position: absolute;
  top: 50%;
  right: 11.5%;
  font-size: 18px;
}

.form-v10-content .form-detail .form-left .select-btn {
  color: #666;
}
.form-v10-content .form-detail .form-right .select-btn {
  color: #f2f2f2;
}
.form-v10-content .form-detail .form-group .form-row.form-row-4 .select-btn {
  top: 20%;
  right: 26%;
}
.modal-form
  .form-v10-content
  .form-detail
  .form-group
  .form-row.form-row-4
  .select-btn {
  top: 50%;
  right: 26%;
}
.form-v10-content
  .form-detail
  .form-right
  .form-group
  .form-row.form-row-2
  .select-btn {
  top: 20%;
  right: 19%;
}
.modal-form
  .form-v10-content
  .form-detail
  .form-right
  .form-group
  .form-row.form-row-2
  .select-btn {
  top: 50%;
  right: 19%;
}
.form-v10-content .form-detail .form-left input {
  color: #000;
}
.form-v10-content .form-detail .form-right input {
  color: #fff;
}
.form-v10-content .form-detail .form-left input,
.form-v10-content .form-detail .form-left select {
  border-bottom: 1px solid #ccc;
}
.form-v10-content .form-detail .form-left input:focus,
.form-v10-content .form-detail .form-left select:focus {
  border-bottom: 1px solid #999;
}
.form-v10-content .form-detail .form-right input,
.form-v10-content .form-detail .form-right select {
  border-bottom: 1px solid;
  border-bottom-color: rgba(255, 255, 255, 0.3);
}
.form-v10-content .form-detail .form-right input:focus,
.form-v10-content .form-detail .form-right select:focus {
  border-bottom: 1px solid #ccc;
}
.form-v10-content .form-detail .form-right select option {
  background: #fff;
}
.form-v10-content .form-detail .form-checkbox {
  margin-top: 37px;
  padding: 0 50px 0 60px;
  position: relative;
}
.form-v10-content .form-detail .form-checkbox input {
  position: absolute;
  opacity: 0;
}
.form-v10-content .form-detail .form-checkbox .checkmark {
  position: absolute;
  top: 1px;
  left: 60px;
  height: 15px;
  width: 15px;
  border: 1px solid #e5e5e5;
  cursor: pointer;
}
.form-v10-content .form-detail .form-checkbox .checkmark::after {
  content: "";
  position: absolute;
  left: 5px;
  top: 1px;
  width: 3px;
  height: 8px;
  border: 1px solid #fff;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  display: none;
}
.form-v10-content
  .form-detail
  .form-checkbox
  input:checked
  ~ .checkmark::after {
  display: block;
}
.form-v10-content .form-detail .form-checkbox p {
  margin-left: 34px;
  color: #e5e5e5;
  font-size: 14px;
  font-weight: 400;
}
.form-v10-content .form-detail .form-checkbox .text {
  font-weight: 400;
  color: #fff;
  text-decoration: underline;
}
.form-v10-content .form-detail .form-right .form-row-last {
  padding-left: 60px;
  margin: 44px 0 10px;
}
.form-v10-content .form-detail .form-right .register {
  background: #fff;
  border-radius: 25px;
  -o-border-radius: 25px;
  -ms-border-radius: 25px;
  -moz-border-radius: 25px;
  -webkit-border-radius: 25px;
  box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.15);
  -o-box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.15);
  -ms-box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.15);
  -moz-box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: 0px 6px 17px 0px rgba(0, 0, 0, 0.15);
  width: 180px;
  border: none;
  margin: 6px 0 50px 0px;
  cursor: pointer;
  color: #333;
  font-weight: 700;
  font-size: 15px;
}
.form-v10-content .form-detail .form-right .register:hover {
  background: #ccc;
}
.form-v10-content .form-detail .form-right .form-row-last input {
  padding: 12.5px;
}
.form-v10-content .form-detail .form-left input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #666;
  font-size: 16px;
}
.form-v10-content .form-detail .form-left input::-moz-placeholder {
  /* Firefox 19+ */
  color: #666;
  font-size: 16px;
}
.form-v10-content .form-detail .form-left input:-ms-input-placeholder {
  /* IE 10+ */
  color: #666;
  font-size: 16px;
}
.form-v10-content .form-detail .form-left input:-moz-placeholder {
  /* Firefox 18- */
  color: #666;
  font-size: 16px;
}
.form-v10-content .form-detail .form-right input::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #f2f2f2;
  font-size: 16px;
}
.form-v10-content .form-detail .form-right input::-moz-placeholder {
  /* Firefox 19+ */
  color: #f2f2f2;
  font-size: 16px;
}
.form-v10-content .form-detail .form-right input:-ms-input-placeholder {
  /* IE 10+ */
  color: #f2f2f2;
  font-size: 16px;
}
.form-v10-content .form-detail .form-right input:-moz-placeholder {
  /* Firefox 18- */
  color: #f2f2f2;
  font-size: 16px;
}

/* Responsive */
@media screen and (max-width: 1199px) {
  .form-v10-content {
    margin: 95px 20px;
  }
}
@media screen and (max-width: 991px) and (min-width: 768px) {
  .form-v10-content .form-detail .form-group {
    flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
  }
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-1,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-2,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-3,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-4,
  .form-v10-content .form-detail .form-right .form-group .form-row.form-row-1,
  .form-v10-content .form-detail .form-right .form-group .form-row.form-row-2 {
    width: auto;
    padding: 0 50px 0 60px;
  }
  .form-v10-content .form-detail .select-btn,
  .form-v10-content
    .form-detail
    .form-left
    .form-group
    .form-row.form-row-4
    .select-btn,
  .form-v10-content
    .form-detail
    .form-right
    .form-group
    .form-row.form-row-2
    .select-btn {
    right: 15%;
  }
}
@media screen and (max-width: 767px) {
  .form-v10-content .form-detail {
    flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
  }
  .form-v10-content .form-detail .form-right {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 10px;
  }
  .form-v10-content .form-detail .form-left {
    padding-bottom: 50px;
  }
}
@media screen and (max-width: 575px) {
  .form-v10-content .form-detail .form-group {
    flex-direction: column;
    -o-flex-direction: column;
    -ms-flex-direction: column;
    -moz-flex-direction: column;
    -webkit-flex-direction: column;
  }
  .form-v10-content .form-detail .form-row,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-1,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-2,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-3,
  .form-v10-content .form-detail .form-left .form-group .form-row.form-row-4,
  .form-v10-content .form-detail .form-right .form-group .form-row.form-row-1,
  .form-v10-content .form-detail .form-right .form-group .form-row.form-row-2 {
    width: auto;
    padding: 0 30px;
  }
  .form-v10-content .form-detail .select-btn,
  .form-v10-content
    .form-detail
    .form-left
    .form-group
    .form-row.form-row-4
    .select-btn,
  .form-v10-content
    .form-detail
    .form-right
    .form-group
    .form-row.form-row-2
    .select-btn {
    right: 15%;
  }
  .form-v10-content .form-detail h2 {
    padding: 33px 30px 0px 30px;
  }
  .form-v10-content .form-detail .form-checkbox {
    padding: 0 30px;
  }
  .form-v10-content .form-detail .form-checkbox .checkmark {
    left: 30px;
  }
  .form-v10-content .form-detail .form-right .form-row-last {
    padding-left: 0;
    text-align: center;
    margin: 44px 0 30px;
  }
}
.MuiDataGrid-root.MuiDataGrid-root {
  height: 98% !important;
}
.MuiDataGrid-cell {
  border-right: 1px solid rgba(224, 224, 224, 1) !important;
}
.MuiDataGrid-root {
  border-bottom: 0 !important;
}
.loading-circular {
  position: relative;
  left: 45%;
  top: 35%;
}
/* Back gorund login */
.background-video {
  width: 100%;

  position: fixed;
  top: 0;
  left: 0;
}
.footer {
  position: relative;
  z-index: 99;
}
.login {
  top: 12%;
  right: 4%;
}
.hyosung-logo {
  position: relative;
  z-index: 999;
}
input[type="date"]::before {
  content: attr(data-placeholder);
  width: 100%;
  color: rgb(102, 102, 102);
}

/* hide our custom/fake placeholder text when in focus to show the default
 * 'mm/dd/yyyy' value and when valid to show the users' date of birth value.
 */
input[type="date"]:focus::before,
input[type="date"]:valid::before {
  display: none;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.text-white.button-edit.ml-3.bg-success {
  min-width: 40px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.text-white.button-delete.bg-danger {
  min-width: 40px !important;
}
button.MuiButtonBase-root.MuiButton-root.MuiButton-text.text-white.button-rehire.bg-primary {
  min-width: 40px !important;
}
.MuiPaper-root.MuiAlert-root.MuiAlert-standardSuccess.MuiPaper-elevation0 {
  width: 20%;
  position: absolute;
  right: 0;
  margin-top: -15px;
  animation: myfirst 4s 1;
  animation-direction: alternate;
}

.MuiTabs-flexContainer {
  display: flex;
  justify-content: space-evenly;
}
label.editLabel {
  padding-left: 15px;
  color: navy;
}
.MuiDataGrid-columnHeaderTitle {
  color: var(--main-color);
}
.download-cv-button {
  color: rgb(0, 185, 71) !important;
}
input#remark {
  resize: none;
  background-color: rgba(0, 0, 0, 0);
}
.modal-form .form-right label {
  color: yellow;
}
.mdb-datatable-filter input.form-control.form-control-sm.ml-0.my-1 {
  display: none;
}
button#mui-67210 {
  display: none;
}
button#mui-38267 {
  display: none;
}
.filterDateLabel {
  display: inherit;
  align-items: inherit;
  justify-content: inherit;
  color: #3f51b5;
}
.filterDate {
  border: 1px solid;
  padding: 1px 3px 0 10px;
}
table.dataTable thead .sorting_desc {
  background-image: none !important;
}
table.dataTable thead .sorting_desc:active {
  background-image: none !important;
}
.ReactModal__Content.ReactModal__Content--after-open {
  inset: 20px !important;
}
.ReactModal__Overlay.ReactModal__Overlay--after-open {
  z-index: 9999999 !important;
}
// .page-wrapper {
//   margin-top: 10vh;
// }
ul.pro-body:hover {
  background-attachment: darkblue !important;
}
img#main-logo {
  width: 87%;
}
.table__item-inner {
  font-family: "quadrat";
  color: dodgerblue;
}
.support-info p {
  font-size: 17px;
}
.dataTables_length.bs-select {
  display: none;
}
a#mobile-collapse1 {
  display: none;
}
.checkTable tbody tr:hover {
  color: royalblue;
}
.loading {
  margin-right: 45%;
  margin-top: 2%;
}
.MuiGridPanel-root {
  top: -59px !important;
}
.importSuccessNotifi {
  margin-top: -80px !important;
}
.hr-input-form {
  height: 680px;
}
.multiModalEdit i.zmdi.zmdi-chevron-down {
  display: none;
}
nav.pcoded-navbar.menu-light.menupos-fixed {
  z-index: 0;
}
span.MuiCheckbox-root.MuiCheckbox-colorPrimary.MuiButtonBase-root.Mui-disabled.MuiCheckbox-root.MuiCheckbox-colorPrimary.PrivateSwitchBase-root.Mui-checked.Mui-disabled {
  color: red !important;
}
span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.Mui-disabled {
  color: red !important;
}

table.report-table th {
  padding: 8px 20px !important;
}
table.report-table td {
  padding: 8px 20px !important;
}
.hidden-overflow {
  overflow: auto;
}
table.report-table .plant-col {
  padding-right: 150px !important;
  text-align: center;
}
.report-table {
  width: 100%;
}
.rowColor th {
  background-color: #c1e7f5;
}
.rowColorTD {
  background-color: #c1e7f5;
}
.dColor {
  background-color: #c1e7f5;
}
.bg-space {
  background-color: #ecf0f5;
}
.datePicker {
  border: 1px solid #c4c4c4;
  border-radius: 5px;
  background-color: #fff;
  padding: 3px 5px;
  box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.1);
  width: 190px;
}
.clearSearchButton {
  padding: 10px 6px;
}
